import React from 'react';
import { Trans } from 'react-i18next';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import BusinessBenefits from '../components/business-benefits';
import BulletPoint from '../components/bullet-point';
import Image from '../components/image';
import PressQuotes from '../components/press-quotes';
import DutyFreeDistrictAnnouncement from '../components/duty-free-district-announcement';

import './business.scss';
import { useTranslation } from 'react-i18next';
import LocalizedLink from '../components/localized-link';
import SmoothScroll from '../components/smooth-scroll';
import LanguageSpecific from '../components/language-specific';
import { languages } from '../config/languages';

const BusinessPage = () => {
  const { t } = useTranslation('BusinessPage');

  return (
    <Layout>
      <SEO title={t('pageTitle')} />
      <Hero>
        <h1>
          <Trans i18nKey="BusinessPage:hero.title">
            <span className="u-underline-styled"></span>
          </Trans>
        </h1>
        <p>{t('hero.subtitle')}</p>

        <div className="section no-padding-bottom">
          <div className="buttons">
            <a href="https://signup.stampit.co/" className="button is-primary is-medium">
              {t('phrase.createFreeAccount')}
            </a>

            <SmoothScroll to="benefits" className="button is-medium is-light">
              {t('Global:phrase.learnMore')}
            </SmoothScroll>
          </div>

          <LanguageSpecific includeLangs={[languages.it.locale]}>
            <div className="st-p-business__partner">
              <span className="st-p-business__partner-label">{t('partnersWith')}</span>
              <Image
                filename="third-party-logos/confcommercio--black.png"
                className="st-p-business__partner-logo"
              ></Image>
            </div>
          </LanguageSpecific>

          <div className="st-p-business__announcement">
            <DutyFreeDistrictAnnouncement></DutyFreeDistrictAnnouncement>
          </div>
        </div>
      </Hero>

      <div id="benefits" className="st-p-landing__section st-p-business__benefits">
        <div className="container">
          <div className="st-p-landing__subtitle">{t('benefits.subtitle')}</div>
          <h2 className="title is-1 is-spaced">
            <Trans i18nKey="BusinessPage:benefits.title">
              <em></em>
            </Trans>
          </h2>

          <div className="section no-padding-bottom">
            <BusinessBenefits></BusinessBenefits>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section st-p-business__stats">
        <div className="container">
          <h2 className="st-p-landing__subtitle">{t('stats.subtitle')}</h2>
          <p className="title is-1 has-text-white st-p-business__stats-title">
            <Trans i18nKey="BusinessPage:stats.title">
              <em></em>
            </Trans>
          </p>

          <div className="columns">
            <div className="column">
              <div className="st-p-business__stat-value">
                {t('stats.easierToUpsell.value')}
                <span className="st-p-business__stat-symbol">%</span>
              </div>
              <div className="st-p-business__stat-subtitle">
                {t('stats.easierToUpsell.description')}
              </div>
            </div>
            <div className="column">
              <div className="st-p-business__stat-value">
                {t('stats.soldMore.value')}
                <span className="st-p-business__stat-symbol">%</span>
              </div>
              <div className="st-p-business__stat-subtitle">{t('stats.soldMore.description')}</div>
            </div>
            <div className="column">
              <div className="st-p-business__stat-value">
                {t('stats.wouldLoseSale.value')}
                <span className="st-p-business__stat-symbol">%</span>
              </div>
              <div className="st-p-business__stat-subtitle">
                {t('stats.wouldLoseSale.description')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section st-p-business__why-stamp">
        <div className="container">
          <h2 className="st-p-landing__subtitle">{t('whyStamp.subtitle')}</h2>
          <p className="title is-1 is-spaced">
            <Trans i18nKey="BusinessPage:whyStamp.title">
              <em className="u-underline-styled"></em>
            </Trans>
          </p>

          <div className="section">
            <div className="columns">
              <div className="column">
                <BulletPoint>
                  <div className="content">
                    <h4>{t('whyStamp.free.title')}</h4>
                    <p className="subtitle is-6">{t('whyStamp.free.description')}</p>
                  </div>
                </BulletPoint>
              </div>
              <div className="column">
                <BulletPoint>
                  <div className="content">
                    <h4>{t('whyStamp.setup.title')}</h4>
                    <p className="subtitle is-6">{t('whyStamp.setup.description')}</p>
                  </div>
                </BulletPoint>
              </div>
              <div className="column">
                <BulletPoint>
                  <div className="content">
                    <h4>{t('whyStamp.easy.title')}</h4>
                    <p className="subtitle is-6">{t('whyStamp.easy.description')}</p>
                  </div>
                </BulletPoint>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <BulletPoint>
                  <div className="content">
                    <h4>{t('whyStamp.riskFree.title')}</h4>
                    <p className="subtitle is-6">{t('whyStamp.riskFree.description')}</p>
                  </div>
                </BulletPoint>
              </div>
              <div className="column">
                <BulletPoint>
                  <div className="content">
                    <h4>{t('whyStamp.notExclusive.title')}</h4>
                    <p className="subtitle is-6">{t('whyStamp.notExclusive.description')}</p>
                  </div>
                </BulletPoint>
              </div>
              <div className="column">
                <BulletPoint>
                  <div className="content">
                    <h4>{t('whyStamp.support.title')}</h4>
                    <p className="subtitle is-6">{t('whyStamp.support.description')}</p>
                  </div>
                </BulletPoint>
              </div>
            </div>
          </div>

          <div className="section buttons is-centered">
            <a href="https://signup.stampit.co/" className="button is-primary is-medium">
              {t('phrase.tryStampForYourBusiness')}
            </a>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section st-p-business__testimonials">
        <div className="container">
          <div className="st-p-business__testimonials-content">
            <div className="columns">
              <div className="column is-four-fifths">
                <h2 className="st-p-landing__subtitle">{t('testimonials.subtitle')}</h2>
                <p className="title is-1 has-text-white">{t('testimonials.title')}</p>
              </div>
            </div>

            <div className="section no-padding-bottom">
              <div className="st-p-business__testimonials-body">
                <blockquote className="st-p-business__testimonials-quote">
                  <p>{t('testimonials.cassina.quote')}</p>
                  <footer>
                    <div className="st-p-business__testimonials-name">
                      {t('testimonials.cassina.name')}
                    </div>
                    <div className="st-p-business__testimonials-role">
                      {t('testimonials.cassina.jobTitle')}
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
          <div className="st-p-business__testimonials-picture">
            <Image filename="testimonial-picture.jpg"></Image>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section has-text-centered">
        <div className="container">
          <p className="title is-2 is-spaced">{t('taxRefundComparison.title')}</p>
          <p className="subtitle is-5">{t('taxRefundComparison.paragraph')}</p>
          <p className="subtitle is-3 mb-0"> {t('taxRefundComparison.stat')}</p>
          <div className="section no-padding-bottom">
            <p className="title is-4">
              <Trans i18nKey="BusinessPage:taxRefundComparison.cta">
                <span className="u-underline-styled"></span>
              </Trans>
            </p>

            <div className="section pt-4 buttons is-centered">
              <div className="control">
                <a href="https://signup.stampit.co/" className="button is-primary is-medium">
                  {t('phrase.createFreeAccount')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section st-p-business__faq">
        <div className="container">
          <h2 className="is-sr-only">{t('faq.subtitle')}</h2>
          <p className="title is-3 is-spaced">{t('faq.title')}</p>

          <div className="section">
            <div className="columns">
              <div className="column is-half">
                <div className="st-p-business__question">
                  <p className="title is-5 is-spaced">{t('faq.whatIsTaxFree.title')}</p>
                  <p className="subtitle is-6">{t('faq.whatIsTaxFree.description')}</p>
                </div>
              </div>
              <div className="column is-half">
                <div className="st-p-business__question">
                  <p className="title is-5 is-spaced">{t('faq.cost.title')}</p>
                  <p className="subtitle is-6">{t('faq.cost.description')}</p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-half">
                <div className="st-p-business__question">
                  <p className="title is-5 is-spaced">{t('faq.usingAgencies.title')}</p>
                  <p className="subtitle is-6">{t('faq.usingAgencies.description')}</p>
                </div>
              </div>
              <div className="column is-half">
                <div className="st-p-business__question">
                  <p className="title is-5 is-spaced">{t('faq.riskFree.title')}</p>
                  <p className="subtitle is-6">{t('faq.riskFree.description')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section st-p-travelers__press">
        <div className="container">
          <h2 className="st-p-landing__subtitle">{t('PressQuotes:title')}</h2>
          <PressQuotes></PressQuotes>
        </div>
      </div>

      <div id="download" className="st-p-landing__section st-p-travelers__final-cta">
        <div className="container">
          <h2 className="st-p-landing__subtitle">{t('finalCta.subtitle')}</h2>

          <div className="columns is-vcentered">
            <div className="column is-half">
              <p className="title is-1 is-spaced">{t('finalCta.title')}</p>
            </div>
            <div className="column is-half">
              <div className="section no-padding-bottom">
                <div className="buttons is-centered">
                  <a href="https://signup.stampit.co/" className="button is-primary is-medium">
                    {t('phrase.createFreeAccount')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="st-p-travelers__secondary-cta">
        <div className="container">
          <div className="st-p-travelers__secondary-cta-text">
            <p>
              <Trans i18nKey="BusinessPage:phrase.travelersAside">
                <LocalizedLink to="/"></LocalizedLink>
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BusinessPage;
